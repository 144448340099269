import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useReactToPrint } from "react-to-print";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Button, Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-google-flight-datepicker/dist/main.css";
import { RangeDatePicker } from "react-google-flight-datepicker";
import { apiCalll, ModelStateMessage } from "../../../../../_metronic/_helpers/apiHelpers";
import PayInvoice from "../manageorders/pharmacy-details/PayInvoice";
import Invoice from "../manageorders/pharmacy-details/Invoice";
import ReactSelect from "react-select";
import {
  Card,
  CardBody,
  Pagination,
} from "../../../../../_metronic/_partials/controls";
import {
  sortCaret,
  headerSortingClasses,
  PleaseWaitMessage,
  NoRecordsFoundMessage,
  toAbsoluteUrl,
  sortCaretTwo,
} from "../../../../../_metronic/_helpers";
import Swal from "sweetalert2";
import html2pdf from 'html2pdf.js';
import { ExportAs, formatNumberWithCommas } from "../../../../../_metronic/_helpers/GlobalHelpers";
import CreditMemos from "../manageorders/pharmacy-details/CreditMemos";

const Invoicing = (props) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const [search, setSearch] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [pagination, setPagination] = useState([]);
  const [pharmacies, setPharmacies] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [id, setId] = useState("");
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [show, setShow] = useState(false);
  const [showBtnPay, setShowBtnPay] = useState(false);
  const [showExtra, setShowExtra] = useState(false);
  const [disablePay, setDisablePay] = useState(false);
  const [extra, setExtra] = useState(0);
  const [adjustmentDescription, setAdjustmentDescription] = useState("");
  const [showPay, setShowPay] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [showCard, setShowAddCard] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [paySelected, setPaySelected] = useState(false);
  const [hiddenCard, setHiddenCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const componentRef = useRef();
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [totalSelectedCreditMemo, setTotalSelectedCreditMemo] = useState(0);
  const [selectedCreditMemo, setSelectedCreditMemo] = useState([]);
  const [showCreditMemo, setShowCreditMemo] = useState(false);
  const [creditMemoCodeNumbers, setCreditMemoNumber] = useState([]);
  const [showAdjust, setShowAdjust] = useState(true);

  const downloadBillAsPDF = async () => {
    const element = componentRef.current;

    const timestamp = new Date().getTime();
    const invoice = invoices;
    const filename = invoice?.invoiceNumber
      ? `invoice-${invoice.invoiceNumber}.pdf`
      : `invoice-${timestamp}.pdf`;

    const options = {
      margin: 0,
      filename,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1, logging: false },
      jsPDF: {
        unit: 'in',
        format: 'a3',
        orientation: 'portrait',
      },
    };

    try {
      await html2pdf().set(options).from(element).save();
    } finally {
      setDownloadIsLoading(false);
    }
  };
  const [ids, setIds] = useState([]);
  const [initFilter, setinitFilter] = useState({
    page: 1,
    sizePerPage: 10,
    sortField: "Id",
    sortOrder: "desc",
    totalSize: 10,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "All", value: 999999 },
    ],
  });

  useEffect(() => {
    if (!show) {
      setExtra(0);
      setAdjustmentDescription("");
      setSelectedCreditMemo([])
      setTotalSelectedCreditMemo(0)
      setCreditMemoNumber([])
    }
  }, [show]);

  useEffect(() => {
    setinitFilter({
      page: 1,
      sizePerPage: 10,
      sortField: "Id",
      sortOrder: "desc",
      custom: true,
      totalSize: 100,
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "All", value: 999999 },

      ],
    });
  }, []);

  const GetHandlerTableChange = (type, newState) => {
    let values = { ...initFilter };
    if (type === "sort") {
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
    }
    if (type === "pagination") {
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
    }
    setinitFilter(values);
  };

  function getTimeZoneOffset() {
    const offsetInMinutes = new Date().getTimezoneOffset();

    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const sign = offsetInMinutes < 0 ? "+" : "-";

    return `${sign}${hours.toString().padStart(2, "")}`;
  }

  const firstLetterUppercase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const handleGetPharmacies = (filter) => {
    const sortFiler = JSON.parse(localStorage.getItem("InvoicingBillingSort"));
    filter.sortField = sortFiler?.sortField || "Id";
    filter.sortOrder = sortFiler?.sortOrder || "desc";
    fetch(
      `${BASE_URL}/invoicing?pageNumber=${filter?.page}&pageSize=${filter?.sizePerPage
      }&search=${search || ""}&paymentMethod=${(paymentMethod === "All" ? "" : paymentMethod) ||
      ""}&startDate=${startDate || ""}&endDate=${endDate ||
      ""}&timezone=${getTimeZoneOffset()}&sortOrder=${filter?.sortOrder
      }&sortField=${firstLetterUppercase(filter?.sortField)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setPharmacies(data[1]?.pharmacies);
          setPagination(data[1]?.pagination);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGetTotalInvoiceByDate = () => {
    setIsLoading(true);
    setDisablePay(false);
    fetch(
      `${BASE_URL}/Pharmacies/GetTotalInvoiceByDate?pharmacyId=${id}&Extra=${extra ||
      0}&search=${search || ""}&startDate=${startDate ||
      ""}&endDate=${endDate || ""}&timezone=${getTimeZoneOffset()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setShow(true);
          setInvoices(data[1]);
        } 
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const GetTotalInvoice = () => {
    setIsLoading(true);
    setDisablePay(false);
    fetch(
      `${BASE_URL}/Pharmacies/GetTotalInvoice?pharmacyId=${id}&Extra=${extra ||
      0}&ids=${ids.join("&ids=")}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        Allow: "GET",
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setShow(true);
          setInvoices(data[1]);
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
         else {
          ModelStateMessage(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      handleGetPharmacies(initFilter);
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [initFilter, search, startDate, endDate, paymentMethod]);

  useEffect(() => {
    if (id) {
      handleGetTotalInvoiceByDate();
    }
  }, [id]);

  useEffect(() => {
    if (!show) {
      setId("");
      setInvoiceNumber("");
      setShowAdjust(true)
    }
  }, [show]);

  function camelCaseToReadable(camelCaseStr) {
    let result = camelCaseStr.replace(/([A-Z])/g, ' $1');
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  }


  const columns = [
    {
      dataField: "name",
      sortCaret: sortCaret,
      text: "Store Name",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("InvoicingBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <p
          className="text-info font-weight-bolder text-hover-muted mb-1 font-size-lg"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/pharmacy/${row.id}/details`);
          }}
        >
          {row.name}
        </p>
      ),
    },
    {
      dataField: "contactFirstName",
      text: "Contact Name",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("InvoicingBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <p>{row.contactFirstName + " " + row.contactLastName}</p>
      ),
    },
    {
      dataField: "contactPhoneNumber",
      text: "Contact Phone",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("InvoicingBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <p
          onClick={() => {
            window.open(`tel:${row.contactPhoneNumber}`);
          }}
          className="text-info font-weight-bolder text-hover-muted mb-1 font-size-lg cursor-pointer">{row.contactPhoneNumber}</p>
      ),
    },
    {
      dataField: "contactEmail",
      text: "Email",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("InvoicingBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "address1",
      text: "Address",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("InvoicingBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) =>
        row.address1 && (
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip id={`tooltip-${"placement"}`}>
                {row.address1 +
                  (row.address2 !== null &&
                    row.address2 !== undefined &&
                    row.address2 !== ""
                    ? ", " + row.address2
                    : "") +
                  (row.city !== null &&
                    row.city !== undefined &&
                    row.city !== ""
                    ? ", "
                    : "") +
                  row.city +
                  (row.state !== null &&
                    row.state !== undefined &&
                    row.state !== "" &&
                    row.zipCode !== null &&
                    row.zipCode !== undefined &&
                    row.zipCode !== ""
                    ? ", "
                    : "") +
                  row.state +
                  " " +
                  row.zipCode}
              </Tooltip>
            }
          >
            <span
              style={{ cursor: "pointer" }}
              className="svg-icon menu-icon svg-icon-info"
            >
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
            </span>
          </OverlayTrigger>
        ),
    },
    {
      dataField: "ordersCount",
      sortCaret: sortCaret,
      text: "Orders",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("InvoicingBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <p
          className="text-info font-weight-bolder text-hover-muted mb-1 font-size-lg"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(`/pharmacy/${row.id}/details/invoice`);
          }}
        >
          {formatNumberWithCommas(row.ordersCount)}
        </p>
      ),
    },
    {
      dataField: "amount",
      text: "Subtotal",
      sort: true,
      onSort: (field, order) => {
        localStorage.setItem("InvoicingBillingSort",JSON.stringify({
            sortField: field,
            sortOrder: order,
          })
        );
      },
      sortCaret: sortCaret,
      formatter: (cellContent, row) => (
        <>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(row.amount || 0)
          }
        </>
      ),
      headerSortingClasses,
    },
    {
      dataField: "invoice",
      text: "Actions",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent, row) => (
        <button
          onClick={() => {
            setId(row.id);
            setShowBtnPay(false);
            setShowExtra(true);
            setPaySelected(false);
          }}
          className={`btn btn-sm btn-secondary font-weight-bolder font-size-sm`}
        >
          Invoice
        </button>
      ),
    },
  ];
  const paginationOptions = {
    custom: true,
    totalSize: pagination?.totalItemCount || 0,
    sizePerPageList: initFilter?.sizePerPageList || [],
    sizePerPage: pagination?.pageSize || 10,
    page: pagination?.pageNumber || 1,
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const methods = [
    { value: "", label: "All" },
    { value: "card", label: "Card" },
    { value: "cash", label: "Cash" },
    { value: "bank transfer", label: "Bank transfer" },
    { value: "check", label: "check" },
    { value: "acss_debit", label: "Pre-Authorized Debit" },
  ];



  useEffect(() => {
    try {
      var resultFilter = JSON.parse(localStorage.getItem("filterInvoicingBilling"));
      setSearch(resultFilter?.search);
      if (!!resultFilter?.startDate && resultFilter?.startDate !== "Invalid date") setStartDate(resultFilter?.startDate);
      if (!!resultFilter?.endDate && resultFilter?.endDate !== "Invalid date") setEndDate(resultFilter?.endDate);
      setPaymentMethod(resultFilter?.paymentMethod);
    } catch (err) { }
  }, []);

  const handleClearCod = () => {
    fetch(`${BASE_URL}/Pharmacies/ClearCod?pharmacyId=${id}&search=${search ||
      ""}&startDate=${startDate || ""}&endDate=${endDate ||
      ""}&timezone=${getTimeZoneOffset()}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      Allow: "PUT",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (
          contentType && contentType.indexOf("application/json") !== -1
        ) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then(data => {
        if (data[0] === 200) {
          handleGetTotalInvoiceByDate();
        }
        else if (data[0] === 400 && data[1].message) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data[1].message,
          })
        }
        else if (data[0] === 400) {
          let tt = JSON.parse(data[1]);
          for (const [key, value] of Object.entries(tt.errors)) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: value,
            })
          }
        }
        else if (data[0] === 401) {
          history.push("/logout");
        }
        else if (data[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getTimeZoneOffset() {
    const offsetInMinutes = new Date().getTimezoneOffset();

    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const sign = offsetInMinutes < 0 ? "+" : "-";

    return `${sign}${hours.toString().padStart(2, "")}`;
  }
  function generateMessage(response) {
    let message = `<div class="text-start">`;

    if (response?.errors && response?.errors.length > 0) {
      message += `<span>Errors: ${response?.errorCount}</span><br><br>
      <span class="mt-3">Success: ${response?.successCount}</span><br><br>
        <div class="d-flex justify-content-between align-items-center mb-5"><strong>Errors details</strong> </div>`;
      message += `<table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" style="width: 30%;"><strong>Pharmacies</strong></th>
                            <th scope="col"><strong>Error</strong></th>
                        </tr>
                    </thead>
                    <tbody>`;

      for (let i = 0; i < response?.errors?.length; i++) {
        const error = response.errors[i];
        message += `<tr>
                                      <td>${error.pharmacyName}</td>
                                      <td>${error.message}</td>
                                  </tr>`;
      }


      message += `</tbody></table>`;
    }

    message += `</div>`;
    return message;
  }
  const [isLoadingPharmacy, setIsLoadingPharmacy] = useState(false);

  const PayAllInvoice = () => {
    setIsLoadingPharmacy(true)
    apiCalll({
      method: "POST",
      link: `/Pharmacies/ChargeAllPharmaciesWithCheckPayment?timezone=${getTimeZoneOffset()}${!!startDate === true ? `&startDate=${startDate}` : ""}${!!endDate === true ? `&endDate=${endDate}` : ""}`,
    })
      .then((res) => {
        if (res.length > 0 && res[0] === 200) {
          setIsLoadingPharmacy(false)
          handleGetPharmacies(initFilter);
          let message = `<span>${res[1]?.message}</span><br></br>`;

          if (res[1]?.errors?.length > 0) {
            message += `<span>Errors: ${res[1].errors?.length}</span><br></br>`;
          }

          let errors = "";
          let dataErrors = [];
          dataErrors = res[1]?.errors;

          for (let i = 0; i < dataErrors?.length; i++) {
            const error = dataErrors[i];
            errors += `<tr class="d-flex justify-content-between"><td>${error?.title}</td><td>${error?.errorMessage}</td></tr><br></br>`;
          }

          // Show the message in the alert
          if (res[1]?.errors?.length > 0) {
            Swal.fire({
              icon: res[1].errors.length === 0 ? 'success' : 'warning',
              title: res[1].errors.length === 0 ? 'Success' : 'Completed with Errors',
              html: generateMessage(res[1]),
              width: '500px',
              confirmButtonColor: "#605DFF",
              cancelButtonColor: "#d33",
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "All pharmacies paid successfully!",
              width: '500px',
            });
          }
        }
      })
      .finally(() => {
        setIsLoadingPharmacy(false)
      });
  }
  function handleSubmitCreditMemo() {
    setIsPaying(true);
    const data = {};
    if (paySelected === true) {
      data.ids = ids;
    }
    data.extra = extra || 0
    data.adjustmentDescription = adjustmentDescription
    data.creditMemoIds = selectedCreditMemo;
  
    return apiCalll({
      method: "Post",
      link:
        paySelected === true
          ? `/Pharmacies/CreditMemoSelected/${id}`
          : `/Pharmacies/CreditMemoByDate?pharmacyId=${id}&startDate=${startDate ||
              ""}&endDate=${endDate || ""}&timezone=${getTimeZoneOffset()}`,
      data: data,
    })
      .then((res) => {
        if (res[0] === 200) {
          document.getElementById("refresh_invoices").click();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "The payment has been paid successfully!",
          });
          setShowPay(false);
          setHidden(false);
          setShowBtnPay(true);
          setShowExtra(false)
          setInvoiceNumber(res[1]?.invoiceNumber)
        } else if (res[0] === 402) {
          Swal.fire({
            title: "Info!",
            text: "You have to subscribe first",
            icon: "info",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/user-profile");
            }
          });
        } else {
          ModelStateMessage(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsPaying(false);
      });
  }

    function Export(Type, filter) {
      apiCalll({
        method: "GET",
        link: `/invoicing?pageNumber=${filter?.page}&pageSize=1000000&search=${search || ""}&paymentMethod=${(paymentMethod === "All" ? "" : paymentMethod) ||
        ""}&startDate=${startDate || ""}&endDate=${endDate ||
        ""}&timezone=${getTimeZoneOffset()}&sortOrder=${filter?.sortOrder
        }&sortField=${firstLetterUppercase(filter?.sortField)}`,
      })
        .then(async (result) => {
          if (result[0] === 200) {
            const object = result[1]?.pharmacies.map((item) => {
              return {
                "Store Name": item.name,
                "Contact Name": item.contactFirstName + " " + item.contactLastName,
                "Contact Phone": item.contactPhoneNumber,
                "Email": item.contactEmail,
                "Address Line 1": item.address1,
                "Address Line 2": item.address2,
                "City": item.city,
                "State": item.state,
                "Postal Code": item.zipCode,
                "Country": item.country,
                "Orders": item.ordersCount,
                "Subtotal": item.amount,
              };
            });
            ExportAs(object || [], Type, "Accounts_Receivable");
          }
          else if (result[0] === 401) {
            history.push("/logout");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

  return (
    <>
      <button
        hidden={true}
        id="refresh_invoices"
        type="button"
        className="btn btn-success"
        onClick={() => {
          handleGetPharmacies(initFilter);
        }}
      >
        Reresh Invoices
      </button>
      <Card>
        <CardBody>
          <div className="row mb-7">
            <div className="col-md-3">
              <input
                type="search"
                className="form-control"
                name="search"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);

                  var objectFilter = {
                    search: e.target.value,
                    startDate: startDate,
                    endDate: endDate,
                    paymentMethod: paymentMethod
                  };
                  localStorage.setItem("filterInvoicingBilling", JSON.stringify(objectFilter));
                }}
              />
              <small className="form-text text-muted">
                <b>Search</b> in all fields
              </small>
            </div>
            <div className="col-md-3">
              <RangeDatePicker
                startDate={
                  startDate !== null && moment(startDate).format("YYYY-MM-DD")
                }
                endDate={
                  endDate !== null && moment(endDate).format("YYYY-MM-DD")
                }
                onChange={(startDate, endDate) => {
                  startDate === null
                    ? setStartDate(null)
                    : setStartDate(moment(startDate).format("YYYY-MM-DD"));

                  endDate === null
                    ? setEndDate(null)
                    : setEndDate(moment(endDate).format("YYYY-MM-DD"));

                  var objectFilter = {
                    search: search,
                    startDate: moment(startDate).format("YYYY-MM-DD"),
                    endDate: moment(endDate).format("YYYY-MM-DD"),
                    paymentMethod: paymentMethod
                  };
                  localStorage.setItem("filterInvoicingBilling", JSON.stringify(objectFilter));
                }}
                dateFormat="MM/DD/YYYY"
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date(2100, 0, 1)}
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                disabled={false}
                startWeekDay="monday"
              />
              <small className="form-text text-muted">
                <b>Filter</b> by Date
              </small>
            </div>
            <div className="col-md-3">
              <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                defaultValue={methods[0]}
                isSearchable={true}
                options={methods}
                value={methods.find((method) => method.label === paymentMethod)}
                onChange={(e) => {
                  setPaymentMethod(e.label);
                  var objectFilter = {
                    search: search,
                    startDate: startDate,
                    endDate: endDate,
                    paymentMethod: e.label
                  };
                  localStorage.setItem("filterInvoicingBilling", JSON.stringify(objectFilter));
                }}
              />
              <small className="form-text text-muted">
                <b>Filter</b> by Payment Method
              </small>
            </div>
            <div className="col-md-3 d-flex justify-content-end">
            <Dropdown>
                <Dropdown.Toggle
                  className="mr-3 mb-xl0 mb-lg-0 mb-md-0 mb-3"
                  variant="light"
                  id="dropdown-basic"
                >
                  <span className="svg-icon svg-icon-md svg-icon-dark">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")}
                    />
                  </span>
                  Export as
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      Export("excel", initFilter);
                    }}
                    href={"#"}
                  >
                    <i className="fas fa-file-excel mr-3"></i> Excel
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      Export("csv", initFilter);
                    }}
                    href="#"
                  >
                    <i className="fas fa-file-csv mr-3"></i> Csv
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      Export("json", initFilter);
                    }}
                    href="#"
                  >
                    <i className="fas fa-file mr-3"></i> Json
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex justify-content-end col-12">
             {JSON.parse(localStorage.getItem("InvoicingBillingSort"))?.sortField && <button
                    type="button"
                    onClick={() => {
                      localStorage.setItem("InvoicingBillingSort", JSON.stringify({
                        sortField: "",
                        sortOrder: "desc",
                      }));
                      handleGetPharmacies(initFilter);
                    }}
                    className="btn btn-primary ml-xl-3 ml-lg-3 ml-md-3 ml-0"
                  >
                    {sortCaretTwo(JSON.parse(localStorage.getItem("InvoicingBillingSort"))?.sortOrder, JSON.parse(localStorage.getItem("InvoicingBillingSort"))?.sortField)}
                    {
                      camelCaseToReadable(JSON.parse(localStorage.getItem("InvoicingBillingSort"))?.sortField)
                    }
                    <i className="flaticon-circle font-size-sm ml-3"></i>
                  </button>}
                  <button
                    type="button"
                    onClick={() => {
                      localStorage.removeItem("filterInvoicingBilling");
                      localStorage.removeItem("InvoicingBillingSort");
                      setSearch("");
                      setStartDate(null);
                      setEndDate(null);
                      setPaymentMethod("");
                    }}
                    className="btn btn-outline-danger ml-xl-3 ml-lg-3 ml-md-3 ml-3"
                  >
                    <i className="flaticon-circle font-size-sm"></i>
                    Clear filter
                  </button>
            </div>
          </div>
          {(paymentMethod === "check" && pharmacies.length >0 )&& (
            <div className="d-flex justify-content-end mb-5">
              <button
                type="button"
                disabled={isLoadingPharmacy}
                onClick={() => {
                  PayAllInvoice()
                }}
                className="btn btn-info font-weight-bold "
              >
                <span> <span className="svg-icon svg-icon-2x">
                   {isLoadingPharmacy === true?"Please wait...":<> 
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
                  Pay All Invoice </>}
                  {/*end::Svg Icon*/}
                </span>
                </span>
              </button>
            </div>
          )}
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <Pagination
                  paginationProps={paginationProps}
                  table="invoicesPageSize"
                >
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="id"
                    data={pharmacies || []}
                    columns={columns}
                    defaultSorted={[{ dataField: "Created", order: "desc" }]}
                    onTableChange={GetHandlerTableChange}
                    {...paginationTableProps}
                  >
                    <PleaseWaitMessage entities={pharmacies || []} />
                    <NoRecordsFoundMessage entities={pharmacies || []} />
                  </BootstrapTable>
                </Pagination>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>

      <Modal
        size="xl"
        show={show}
        onHide={() => {
          setShow(false);
        }}
        style={{ display: hidden ? "none" : "block" }}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <Invoice
            componentRef={componentRef}
            invoices={invoices}
            isLoading={isLoading}
            name={props.name}
            startDate={startDate}
            endDate={endDate}
            extra={extra}
            setExtra={setExtra}
            showExtra={showExtra}
            adjustmentDescription={adjustmentDescription}
            setAdjustmentDescription={setAdjustmentDescription}
            GetTotalInvoice={GetTotalInvoice}
            handleGetTotalInvoiceByDate={handleGetTotalInvoiceByDate}
            ids={ids}
            setDisablePay={setDisablePay}
            disablePay={disablePay}
            invoiceNumber={invoiceNumber}
            handleClearCod={handleClearCod}
            showBtnPay={showBtnPay}
            totalSelectedCreditMemo={totalSelectedCreditMemo}
            creditMemoCodeNumbers={creditMemoCodeNumbers}
            showAdjust={showAdjust}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="light-warning"
            className="me-5"
            hidden={showBtnPay}
            onClick={() => {
              setShowCreditMemo(true);
              setHidden(true);
            }}
          >
            Credit Memos
          </Button>
          <div className="my-1 me-5">
            <button
              hidden={showBtnPay}
              disabled={disablePay}
              type="button"
              className="btn btn-info my-1 me-12"
              onClick={() => {
                if (
                  extra !== 0 &&
                  extra !== null &&
                  adjustmentDescription === ""
                ) {
                  Swal.fire({
                    icon: "info",
                    title: "Oops...",
                    text: "Please enter the adjustment description",
                  });
                  return;
                }
                if(totalSelectedCreditMemo !== 0 && (invoices?.totalWithTax + totalSelectedCreditMemo) < 0)
                  {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You want to mark this invoice as credit memo?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, Mark it!",
                      cancelButtonText: "No, cancel!",
                      reverseButtons: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleSubmitCreditMemo();
                      }
                    });
                    return;
                  }
                  if (invoices?.totalWithTax > 0) {
                    setHidden(true);
                    setShowPay(true);
                  }else{
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You want to mark this invoice as credit memo?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes, Mark it!",
                      cancelButtonText: "No, cancel!",
                      reverseButtons: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleSubmitCreditMemo();
                      }
                    });
  
                  }
              }}
            >
              Pay Invoice
            </button>
          </div>
          <div className="my-1 me-5">
            {!showExtra && (
              <>
                <button
                  type="button"
                  className="btn btn-light-success my-1 me-12 mr-5"
                  onClick={handlePrint}
                >
                  Print Invoice
                </button>
                <button
                  type="button"
                  disabled={downloadIsLoading}
                  className="btn btn-light-primary my-1 me-12"
                  onClick={() => {
                    setDownloadIsLoading(true);
                    downloadBillAsPDF()
                  }}
                >
                  {downloadIsLoading ? 'Downloading...' : 'Download PDF'}
                </button>
              </>
            )}

          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={showPay}
        onHide={() => {
          setHidden(false);
          setShowPay(false);
        }}
        style={{ display: hiddenCard ? "none" : "block" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pay Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PayInvoice
            setShowAddCard={setShowAddCard}
            showCard={showCard}
            setHiddenCard={setHiddenCard}
            setShowPay={setShowPay}
            setShow={setShow}
            setHidden={setHidden}
            startDate={startDate}
            endDate={endDate}
            id={id}
            setIsPaying={setIsPaying}
            isPaying={isPaying}
            setShowBtnPay={setShowBtnPay}
            amount={invoices?.totalWithTax + (totalSelectedCreditMemo || 0)}
            paySelected={paySelected}
            ids={ids}
            setIds={setIds}
            extra={extra}
            adjustmentDescription={adjustmentDescription}
            setShowExtra={setShowExtra}
            setInvoiceNumber={setInvoiceNumber}
            selectedCreditMemo={selectedCreditMemo}
            downloadBillAsPDF={downloadBillAsPDF}
            setShowAdjust={setShowAdjust}
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            disabled={isPaying}
            variant="secondary"
            onClick={() => {
              setHidden(false);
              setShowPay(false);
            }}
          >
            Cancel
          </Button>
          <div className="my-1 me-5">
            <button
              disabled={isPaying}
              type="button"
              className="btn btn-primary my-1 me-12"
              onClick={() => {
                document.getElementById("proceed_to_payment").click();
              }}
            >
              {isPaying ? (
                <div className="d-flex align-items-center">
                  Procceding to payment...
                  <span className="spinner-border spinner-border-sm ml-3"></span>
                </div>
              ) : (
                "Proceed to payment"
              )}
            </button>
          </div>
          <div className="my-1 me-5"></div>
        </Modal.Footer>
      </Modal>

      <CreditMemos
        showCreditMemo={showCreditMemo}
        setShowCreditMemo={setShowCreditMemo}
        selectedCreditMemo={selectedCreditMemo}
        setSelectedCreditMemo={setSelectedCreditMemo}
        totalSelectedCreditMemo={totalSelectedCreditMemo}
        setTotalSelectedCreditMemo={setTotalSelectedCreditMemo}
        setHidden={setHidden}
        startDate={startDate}
        endDate={endDate}
        invoiceId={id}
        setCreditMemoNumber={setCreditMemoNumber}
        creditMemoCodeNumbers={creditMemoCodeNumbers}
      />
    </>
  );
};

export default Invoicing;
