import React, { useEffect, useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";
import { useHistory } from "react-router-dom";

const SocialShare = ({flex}) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = JSON.parse(localStorage.getItem("user")).token;
  const history = useHistory();
    const [shareUrl, setShareUrl] = useState("");
  
  const handleGetQrCode2 = () => {
    fetch(`${BASE_URL}/Pharmacies/GetReferral`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      Allow: "GET",
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          setShareUrl(data[1]?.pharmacyLink);
        } else if (data[0] === 401) {
          history.push("/logout");
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("in catch");
      });
  };
  const title = "Check out DeliveryEase!";

  useEffect(() => {
    handleGetQrCode2();
  }, []);

  return (
    <div className="social-share-container">
      <div className={`${flex && "d-flex align-items-center flex-wrap"}`}>
        <h6 className="mt-3 mr-3">Share Invite Link:</h6>
        <FacebookShareButton url={shareUrl} quote={title} className="mr-2">
          <FacebookIcon size={30} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={title} className="mr-2">
          <TwitterIcon size={30} round />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={title} className="mr-2">
          <LinkedinIcon size={30} round />
        </LinkedinShareButton>
        <WhatsappShareButton url={shareUrl} title={title} className="mr-2">
          <WhatsappIcon size={30} round />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default SocialShare;
