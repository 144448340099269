import React from "react";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import Select from "react-select";
import { InputNumber } from "antd";
import moment from "moment";
const Bill = (props) => {
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const methodOptions = [
    {
      value: true,
      label: "Stripe",
    },
    {
      value: false,
      label: "EMT",
    },
  ];

  return (
    <>
      <div className="card" ref={props.componentRef}>
        <div className="card-body py-20">
          <div className="mw-lg-950px mx-auto w-100">
            <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
              <div>
              {props?.billInfos?.rbcConfirmationNumber && <h1 className="pb-7">
                  EMT Confirmation Number: {props?.billInfos?.rbcConfirmationNumber} <span className="text-primary">{`(${props?.billInfos?.rbcStatus})`} </span>
                  </h1>}
              {props?.invoices?.rbcConfirmationNumber && <h1 className="pb-7">
                  EMT Confirmation Number: {props?.invoices?.rbcConfirmationNumber} <span className="text-primary">{`(${props?.invoices?.rbcStatus})`} </span>
                  </h1>}
                <h1 className="pb-7">
                  BILL {props?.invoices?.billNumber && "#"+props?.invoices?.billNumber} {props?.billInfos?.billNumber && "#"+props?.billInfos?.billNumber}
                  </h1>
                  {(props?.startDate && props?.endDate) && <div className="d-flex flex-stack mb-3">
                    <h5 className="fw-semibold pr-2">Pay Period :</h5>
                    <p className="text-end font-size-lg font-weight-semibold mx-3">
                        {
                          moment(props?.startDate).format("MMMM DD, YYYY")
                        }
                        {" - "}
                        {
                          moment(props?.endDate).format("MMMM DD, YYYY")
                        }
                    </p>
                  </div>}
                  {(props?.invoices?.startDate && props?.invoices?.endDate) && <div className="d-flex flex-stack mb-3">
                    <h5 className="fw-semibold pr-2">Pay Period :</h5>
                    <p className="text-end font-size-lg font-weight-semibold mx-3">
                        {
                          moment(props?.invoices?.startDate).format("MMMM DD, YYYY")
                        }
                        {" - "}
                        {
                          moment(props?.invoices?.endDate).format("MMMM DD, YYYY")
                        }
                    </p>
                  </div>}
                <div className="d-flex flex-stack mb-3">
                  <h5 className="fw-semibold pr-2">Date of issue</h5>
                  <p className="text-end font-size-lg font-weight-semibold">
                      {
                       props?.invoices?.created ? moment(props?.invoices?.created).format("MMMM DD, YYYY") :  moment().format("MMMM DD, YYYY")
                      }
                  </p>
                </div>
                <div className="d-flex flex-stack mb-3">
                  <h4 className="fw-semibold pr-2">Date due </h4>
                  <p className="text-end font-size-lg font-weight-semibold">
                     {
                       props?.invoices?.created ? moment(props?.invoices?.created).format("MMMM DD, YYYY") :  moment().format("MMMM DD, YYYY")
                      }
                  </p>
                </div>
              </div>

              <div className="text-sm-end">
                <a href="#">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/logos/logo-letter-2.svg")}
                  />
                </a>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
              <div>
                <h5 className="font-weight-bold pe-5 pb-2">
                { props?.invoices?.businessName || "DeliveryEase Inc."}
                </h5>
                {!props?.invoices?.address && <>
                    <div className="text-end font-size-md font-weight-bolder">
                        2100 Bloor Street West
                    </div>
                        <div className="text-end font-size-md font-weight-bolder">
                          Suite 6164
                        </div>
                      <div className="text-end font-size-md font-weight-bolder">
                        Toronto, ON, M6S 5A5
                      </div>
                  </>}
                  {props?.invoices?.address && <>
                    <div className="text-end font-size-md font-weight-bolder">
                        {props?.invoices?.address?.line1}
                    </div>
                    {props?.invoices?.address?.line2 && (
                        <div className="text-end font-size-md font-weight-bolder">
                          {props?.invoices?.address?.line2}
                        </div>
                      )}
                      <div className="text-end font-size-md font-weight-bolder">
                        {props?.invoices?.address?.city}{", "}
                        {props?.invoices?.address?.state}{", "}
                        {props?.invoices?.address?.postalCode}
                      </div>
                      <div className="text-end font-size-md font-weight-bolder">
                        {props?.invoices?.address?.country === "CA" ? "Canada" : props?.invoices?.address?.country}
                      </div>
                  </>}
                    <div className="text-end font-size-md font-weight-bolder">
                    accounting@deliveryease.co
                  </div>
                  <div className="text-end font-size-md font-weight-bolder">
                  +1 (855) 272-7625
                  </div>
              </div>
              {
                <div className="">
                  <div className="d-flex flex-column">
                    <h5 className="font-weight-bold mb-3">Paid to: </h5>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.driverDetails?.name}
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.driverDetails?.address1}
                    </div>
                    {props?.invoices?.driverDetails?.address2 && (
                      <div className="text-end font-size-md font-weight-bolder">
                        {props?.invoices?.driverDetails?.address2}
                      </div>
                    )}
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.driverDetails?.city}{" "}
                      {props?.invoices?.driverDetails?.state}{" "}
                      {props?.invoices?.driverDetails?.zipCode}
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.driverDetails?.country}
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.driverDetails?.phoneNumber}
                    </div>
                    <div className="text-end font-size-md font-weight-bolder">
                      {props?.invoices?.driverDetails?.email}
                    </div>
                    {props?.invoices?.hst && <div className="text-end font-size-md font-weight-bolder">
                      Tax ID: {props?.invoices?.hst}
                    </div>}
                  </div>
                </div>
              }
            </div>
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="font-weight-bold mb-3">
                  {formatCurrency(props?.invoices?.totalPayedWithTips + (props?.extra || 0))} due{" "}
                  {
                      props?.invoices?.created ? moment(props?.invoices?.created).format("MMMM DD, YYYY") :  moment().format("MMMM DD, YYYY")
                  }
                </h1>
                <div className="d-flex justify-content-between align-items-center">
                  <b className="form-label mr-3">Payment method:</b>
                  {props.showBtnPay ? (
                    <Select
                      className="basic-single w-150px"
                      isSearchable={true}
                      name="paymentMethod"
                      options={methodOptions}
                      value={methodOptions.find(
                        (obj) => obj.value === props?.withStripe
                      )}
                      onChange={(e) => {
                        props.setWithStripe(e.value);
                        if (props.ids.length === 0) {
                          props.handleGetPayoutEverything(e.value);
                        }
                      }}
                    />
                  ) : (
                    <b className="text-primary">
                      {(props?.withStripe ||props?.invoices?.withStripe ) ? "Stripe" : "EMT"}
                    </b>
                  )}
                </div>
              </div>
              <div className="">
                <div className="flex-grow-1 pt-8">
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap table-centered mb-0 border">
                      <thead></thead>
                      <tbody>
                        <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            Orders
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {props?.invoices?.ordersCount}
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            Charges
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.totalCharge)}
                          </td>
                        </tr>
                        {props?.invoices?.totalAdditionalCharges ? <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            Additional charges
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.totalAdditionalCharges)}
                          </td>
                        </tr> : null}
                        {props?.invoices?.pfcCount ? <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            PFC Count
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {new Intl.NumberFormat("en-US").format(props?.invoices?.pfcCount)}
                          </td>
                        </tr> : null}
                       {props?.invoices?.totalPfc ? <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            Orders with PFC
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.totalPfc)}
                          </td>
                        </tr> : null}
                        <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            Subtotal
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.subTotal)}
                          </td>
                        </tr>
                        {props?.invoices?.stripeFee ? <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            Processing fees
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.stripeFee)}
                          </td>
                        </tr> : null}
                        {props?.invoices?.stripeFee ? <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="border-0 text-end"
                          >
                            Total excluding tax
                          </th>
                          <td className="border-0 text-end border-0 font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.amount)}
                          </td>
                        </tr> : null}
                        <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="border-0 text-end"
                          >
                            HST - Canada (
                            {Math.round(props?.invoices?.taxPercentage * 100) /
                              100}
                            % on {formatCurrency(props?.invoices?.amount)})
                          </th>
                          <td className="border-0 text-end font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.hstTax)}
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="border-0 text-end"
                          >
                            Total
                          </th>
                          <td className="border-0 text-end font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.totalWithTax)}
                          </td>
                        </tr>
                        {props?.invoices?.tips ? <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            Tips
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.tips)}
                          </td>
                        </tr> : null}
                        {props?.invoices?.totalDriverAdjustments ?
                        <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            Orders Adjustment
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {formatCurrency(props?.invoices?.totalDriverAdjustments)}
                          </td>
                        </tr> : null}
                        <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="text-end border-0"
                          >
                            {(!props.showBtnPay &&
                              props?.adjustmentDescription) ||
                              props?.invoices?.adjustment ||
                              <>
                              {props.isDetails === false  ?<input
                                type="text"
                                style={{
                                  width: "270px",
                                }}
                                placeholder="Adjustment Description"
                                className="form-control form-control-sm"
                                value={props?.adjustmentDescription}
                                onChange={(e) => {
                                  props.setAdjustmentDescription(
                                    e.target.value
                                  );
                                }}
                              />
                              : "Adjustment"
                            }
                              </>}
                          </th>
                          <td className="text-end border-0 font-size-md font-weight-bolder">
                            {props.showBtnPay && (
                              <InputNumber
                                style={{ width: "270px" }}
                                type="number"
                                addonBefore="$"
                                addonAfter=""
                                placeholder="Enter Amount"
                                value={props?.extra}
                                controls={false}
                                onChange={(e) => {
                                  props.setExtra(e);
                                }}
                              />
                            )}
                            {!props.showBtnPay &&
                              formatCurrency(
                                props?.extra ||
                                  props?.invoices?.driverExtras ||
                                  0
                              )}
                          </td>
                        </tr>
                        {/* {props.showBtnPay && (
                          <tr>
                            <th
                              scope="row"
                              colSpan={2}
                              className="text-end border-0"
                            >
                              Adjustment Description
                            </th>
                            <td className="text-end border-0 font-size-md font-weight-bolder">
                              <input
                                type="text"
                                style={{
                                  width: "270px",
                                }}
                                placeholder="Enter Description"
                                className="form-control form-control-sm"
                                value={props?.adjustmentDescription}
                                onChange={(e) => {
                                  props.setAdjustmentDescription(
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        )} */}
                        <tr>
                          <th
                            scope="row"
                            colSpan={2}
                            className="border-0 text-end"
                          >
                            Amount due
                          </th>
                          <td className="border-0 text-end">
                            <h4 className="m-0 font-size-md font-weight-bolder">
                              {formatCurrency(
                                props?.invoices?.totalPayedWithTips + (props?.extra || 0)
                              )}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="mt-15 pt-15">
                <h5 className="font-weight-bold pe-5 pb-2">
                  DeliveryEase Inc.
                </h5>
                <p className="text-end font-size-md font-weight-bolder">
                  Humbled to be your pharmacy delivery partner 🙂
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bill;
